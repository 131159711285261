
.text-purple {
  color: blueviolet
}

#fadee h1 { 
  margin-top: 200px
}

#fadee.fade.modal-backdrop {
  background-color: #fff;
  opacity: 0.3!important;
}